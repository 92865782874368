<template>
  <div v-if="show" class="modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div
          class="modal-header d-flex justify-content-between align-items-center"
        >
          <h5 class="modal-title">{{ title }}</h5>
          <button type="button" class="close rounded-close" @click="close">
            <span>&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label for="departmentSelect">{{ $t("tools.import_validator.department") }}</label>
            <v-select
              v-model="selected_department_uuid"
              :reduce="(option) => option.department_uuid"
              label="department"
              autocomplete="off"
              :options="departments"
            ></v-select>
          </div>
          <div class="form-group">
            <label for="seasonSelect">{{ $t("season") }}</label>
            <v-select
              v-model="selected_season_uuid"
              :reduce="(option) => option.season_uuid"
              label="season"
              autocomplete="off"
              :options="seasons"
            ></v-select>
          </div>
          <div class="form-group">
            <label for="workProcessSelect">{{ $t("tools.import_validator.work_process") }}</label>
            <v-select
              v-model="selected_work_process"
              :reduce="(option) => option.label"
              taggable
              autocomplete="off"
              :options="work_process"
            ></v-select>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary mr-3" @click="close">
            {{ $t("cancel") }}
          </button>
          <button
            type="button"
            class="btn btn-success"
            @click="
              $emit(
                'confirm',
                selected_department_uuid,
                selected_season_uuid,
                selected_work_process
              );
              selected_department_uuid = null;
              selected_season_uuid = null;
              selected_work_process = null;
            "
          >
            {{ $t("apply_to_selected_rows") }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImportValidator",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    item: {
      type: Object,
      required: false,
    },
    title: {
      type: String,
      default: "Validator",
    },
    departments: {
      type: Array,
      required: true,
    },
    work_process: {
      type: Array,
      required: true,
    },
    seasons: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected_department_uuid: null,
      selected_season_uuid: null,
      selected_work_process: null,
    };
  },
  methods: {
    close() {
        this.selected_department_uuid = null;
        this.selected_season_uuid = null;
        this.selected_work_process = null;
        this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal {
  display: block;
  background: rgba(0, 0, 0, 0.5);
}

.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}

.close {
  border: none;
  background: none;
  font-size: 1.5rem;
  cursor: pointer;
  float: right;
}

.rounded-close {
  border-radius: 50%;
  padding: 0.25rem 0.5rem;
}

.modal-footer .btn-secondary {
  margin-right: 1rem;
}
</style>
