export function getValidatorGridColumns(departments, seasons) {
  return [
    {
      dataField: "registration_number",
      caption: "registration_number",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "department_uuid",
      caption: "department",
      lookup: {
        dataSource: departments,
        valueExpr: "department_uuid",
        displayExpr: "department",
      },
      validationRules: [{ type: "required" }]
    },
    {
      dataField: "start_date",
      caption: "start_date",
      dataType: "datetime",
      format: "yyyy-MM-dd HH:mm",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "end_date",
      caption: "end_date",
      dataType: "datetime",
      format: "yyyy-MM-dd HH:mm",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "person",
      caption: "person",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "work_process",
      caption: "work_process",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "parcel_name",
      caption: "parcel_name",
    },
    {
      dataField: "season_uuid",
      caption: "season",
      lookup: {
        dataSource: seasons,
        valueExpr: "season_uuid",
        displayExpr: "season",
      },
      validationRules: [{ type: "required" }]
    },
    {
      dataField: "culture",
      caption: "culture",
    },
    {
      dataField: "surface",
      caption: "surface",
      dataType: "number",
      //validationRules: [{ type: "range", min: 18, max: 100 }],
      /*fixed: true,
      width: 50,
      fixedPosition: "right",*/
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "time_spent_on_parcel",
      caption: "time_spent_on_parcel",
      validationRules: [{ type: "required" }],
    },
    {
      dataField: "location_category",
      caption: "location_category",
    },
    {
      dataField: "net_surface_worked",
      caption: "net_surface_worked",
      dataType: "number",
    },
    {
      dataField: "work_machine_name",
      caption: "work_machine_name",
    },
    {
      dataField: "ignition_time",
      caption: "ignition_time",
    },
    {
      dataField: "working_time",
      caption: "working_time",
    },
    {
      dataField: "inactive_time",
      caption: "inactive_time",
    },
    {
      dataField: "traveled_distance",
      caption: "traveled_distance",
    },
    {
      dataField: "average_speed",
      caption: "average_speed",
      dataType: "number",
    },
    {
      dataField: "maximum_speed",
      caption: "maximum_speed",
      dataType: "number",
    },
    {
      dataField: "estimated_surface",
      caption: "estimated_surface",
      dataType: "number",
    },
    {
      dataField: "surface_worked_with_overlaps",
      caption: "surface_worked_with_overlaps",
      dataType: "number",
    },
    {
      dataField: "performance",
      caption: "performance",
      dataType: "number",
    },
    {
      dataField: "fuel_consumption",
      caption: "fuel_consumption",
      dataType: "number",
    },
    {
      dataField: "consumption_per_hour",
      caption: "consumption_per_hour",
      dataType: "number",
    },
    {
      dataField: "consumption_per_ha",
      caption: "consumption_per_ha",
      dataType: "number",
    },
    {
      dataField: "starting_machine_engine_hours",
      caption: "starting_machine_engine_hours",
      dataType: "number",
    },
    {
      dataField: "machine_engine_hours",
      caption: "machine_engine_hours",
      dataType: "number",
    },
    {
      dataField: "fuel_norm",
      caption: "fuel_norm",
      dataType: "number",
    },
    {
      dataField: "area_correction_factor",
      caption: "area_correction_factor",
      dataType: "number",
    },
    {
      dataField: "work_hours",
      caption: "salary_work_hours",
      dataType: "number",
    },
    /*{
      dataField: "cost_category",
      caption: "cost_category",
    },
    {
      dataField: "cost",
      caption: "cost",
      dataType: "number",
    },
    {
      dataField: "inactive_work_hours",
      caption: "inactive_work_hours",
      dataType: "number",
    },
    {
      dataField: "product",
      caption: "product",
    },
    {
      dataField: "product_quantity",
      caption: "product_quantity",
    },*/
  ];
}
