<template>
  <card :title="title" id="details" v-if="canEdit || canDelete" v-cloak>
    <div class="row">
      <div class="form-group col-sm-12 col-lg-6 text-left">
        <label class="control-label">{{ $t('common.name') }}</label>
        <input type="text" v-model="data.name" :placeholder='$t("common.name")'
               :class="{ 'field-error' : $v.data.name.$error }"
               class="form-control"/>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 form-group">
        <label class="control-label">{{$t("departments")}}</label>
        <b-form-select
          v-model="data.department_uuid"
          class="form-control"
          :class="{ 'field-error' : $v.data.department_uuid.$error }"
          :disabled="!canEditUnit"
          :options="department_list"></b-form-select>
      </div>
    </div>
    <agro-action-buttons
      :data="data"
      :can-edit="canEdit"
      :can-delete="canDelete && canEditUnit"
      @onSave="save"
      @onSaveAndNew="saveAndNew"
      :save-and-new="true"
      @onDelete="confirmDelete"
      @onGotoList="gotoList"
    ></agro-action-buttons>

  </card>
  <div v-else>
    <forbidden></forbidden>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import required from "vuelidate/src/validators/required";
import {canDelete, canEdit, permissions, permissionTypes} from "@/helpers/Permissions";
import * as actions from '@/helpers/generalActions'
import AgroActionButtons from "@/components/AgroActionButtons/index";
import Forbidden from "@/pages/errors/Forbidden";
import {buildDropdownOptions} from "~/helpers/common";

export default {
  name: 'SMWarehouseDetails',
  components: {Forbidden, AgroActionButtons},
  data() {
    return {
      data: {},
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      users: 'user/users',
      warehouses: 'smWarehouses/smWarehouses',
      departments: 'company/departments',
      domain: 'auth/domain',
    }),
    name() {
      return this.$t('stock_management.warehouses.title')
    },
    config() {
      return {
        permission: permissions.SM_PARTNERS,
        permission_type: permissionTypes.module,
        get: this.get,
        create: this.create,
        update: this.update,
        delete: this.delete,
        list_url: '/stock-management/sm-warehouses',
        confirm_delete: true,
      }
    },
    actions() {
      return actions;
    },
    canEdit() {
      return canEdit(this);
    },
    canDelete() {
      return canDelete(this);
    },
    canEditUnit() {
      return this.data && this.data.products && this.data.products.length === 0 && this.data.transactions && this.data.transactions.length === 0 && this.data.movements && this.data.movements.length === 0;
    },
    title() {
      return (this.data.uuid ? this.$t('common.edit') : this.$t('common.new'))+' '+this.name;
    },
    department_list() {
      return buildDropdownOptions(this.departments, {
        value: 'uuid',
        text: 'name',
      }, {
        value: null,
        text: '-',
      });
    }
  },
  methods: {
    ...mapActions({
      get: 'smWarehouses/getSmWarehouse',
      create: 'smWarehouses/createSmWarehouse',
      update: 'smWarehouses/updateSmWarehouse',
      delete: 'smWarehouses/deleteSmWarehouse',
      getDepartments: 'company/getDepartments',
    }),
    loadData() {
      let vm = this;
      return this.actions.getData(this, (data) => {
        //createCallback
      }, (data) => {
        //updateCallback
      });
    },
    gotoList() {
      return actions.gotoList(this);
    },
    confirmDelete() {
      return this.actions.confirmDelete(this);
    },
    save() {
      return this.actions.save(this, this.config.list_url);
    },
    saveAndNew() {
      return this.actions.saveAndNew(this, '/stock-management/sm-warehouses/add');
    },
    newObject() {
      return this.actions.newObject(this);
    },
  },
  mounted() {
    this.loadData();
    this.actions.handleParams(this);
    this.getDepartments({
      company_uuid: this.domain.uuid,
    });
  },
  watch: {
    $route: {
      handler: function(newVal) {
        this.actions.handleParams(this);
      },
      deep: true,
    },
    permission: {
      handler: function(newVal) {
        this.permissionModule = newVal;
      },
      deep: true,
    },
  },
  validations(){
    return {
      data: {
        name: {
          required,
        },
        department_uuid: {
          required,
        },
      }
    }
  }
}
</script>

<style scoped>

</style>
