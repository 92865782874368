<template>
  <div class="main-layout" v-if="!isRedirect">
    <app-header />
    <div class="sidebar-holder flex-fill d-flex flex-row overflow-auto">
      <aside class="sidebar d-flex flex-column">
        <div class="sidebar-content p-2 overflow-auto sidebar-auth" v-if="authenticated">
          <sidebar
            :data="permissionControlledMenu"
            @onCollapse="onToggleCollapse"
          ></sidebar>
        </div>
      </aside>
      <main class="main flex-fill p-3 overflow-auto" :class="[authenticated, authenticated ? 'child-content-auth' : 'child-content']">
        <router-view />
      </main>
    </div>

    <div class="toast-holder text-center align-items-center justify-content-center">
      <toast-message ref="toastMessage"></toast-message>
    </div>
  </div>
  <div class="d-flex justify-content-center" v-else>
    <loader size="sm" :loading="redirect"/>
  </div>
</template>

<script>
    import 'devextreme/dist/css/dx.light.css';
    import AppHeader from '~/components/AppHeader.vue'
    import {errorMessageList, isMobile} from "~/helpers/common";
    import {mapActions, mapGetters} from 'vuex'
    import {permissions, actions, getPerm, hasModuleAccess} from "~/helpers/Permissions";
    import {eventBus} from "@/plugins/eventBus";
    import {errorMessage} from "@/helpers/common";
    import ToastMessage from "~/components/Mobile/toastMessage.vue";
    import Sidebar from "~/components/Sidebar/index.vue";
    import Loader from "@/components/Loader.vue";
    import Cookies from "js-cookie";

    export default {
        name: 'MainLayout',

        data() {
            return {
                menu_collapsed: true,
                showed: false,
                redirect: false,
            }
        },
        components: {
          Loader,
          Sidebar,
            AppHeader,
            ToastMessage
        },
        computed: {
            ...mapGetters({
                authenticated: 'auth/check',
                user: 'auth/user',
                departments: 'company/departments',
                domain: 'auth/domain'
            }),
            permissionControlledMenu() {
                if(this.menu) {
                    return this.menu.filter((item) => {
                        if(item.permission) {
                            return hasModuleAccess(this.user, item.permission);
                        } else {
                            if(item.child) {
                                item.child = item.child.filter((subitem) => {
                                    if(subitem.permission) {
                                        return hasModuleAccess(this.user, subitem.permission);
                                    } else {
                                        return true;
                                    }
                                });

                                if(item.child && item.child.length <= 0) {
                                    item.child = null;
                                }
                            }

                            return item.child !== null;
                        }
                    })
                }

                return [];
            },
            menu() {
                return [
                    {
                        href: '/dashboard',
                        title: this.$t('dashboard'),
                        icon: 'images/desktop/icons/dashboard.svg'
                    },
                    {
                        href: '/users',
                        title: this.$t('security'),
                        icon: 'images/desktop/icons/security.svg',
                        child: [
                            {
                                href: '/users',
                                title: this.$t('user_management'),
                                permission: permissions.USER_MANAGEMENT,
                            },
                            {
                                href: '/roles-permissions',
                                title: this.$t('roles_permissions'),
                                permission: permissions.ROLES_PERMISSIONS,
                            }
                        ]
                    },
                    {
                      href: '/salary-calculator/sc-work-log',
                      title: this.$t('salary_calculator.title'),
                      icon: 'images/desktop/icons/salary-calculator.svg',
                      child: [
                        {
                          href: '/salary-calculator/sc-holiday',
                          title: this.$t('salary_calculator.holiday.days'),
                          permission: permissions.SC_BONUS,
                        },
                        {
                          href: '/salary-calculator/sc-bonus',
                          title: this.$t('salary_calculator.bonus.title'),
                          permission: permissions.SC_BONUS,
                        },
                        {
                          href: '/salary-calculator/sc-employee-role',
                          title: this.$t('salary_calculator.employee_role.title'),
                          permission: permissions.SC_EMPLOYEE_ROLE,
                        },
                        {
                          href: '/salary-calculator/sc-employee',
                          title: this.$t('salary_calculator.employee.title'),
                          permission: permissions.SC_EMPLOYEE,
                        },
                        {
                          href: '/salary-calculator/sc-measure-unit',
                          title: this.$t('salary_calculator.measure_unit.title'),
                          permission: permissions.SC_MEASURE_UNIT,
                        },
                        {
                          href: '/salary-calculator/sc-shift',
                          title: this.$t('salary_calculator.shift.title'),
                          permission: permissions.SC_SHIFT,
                        },
                        {
                          href: '/salary-calculator/sc-work-group',
                          title: this.$t('salary_calculator.work_group.title'),
                          permission: permissions.SC_WORK_GROUP,
                        },
                        {
                          href: '/salary-calculator/sc-work-type',
                          title: this.$t('salary_calculator.work_type.title'),
                          permission: permissions.SC_WORK,
                        },
                        {
                          href: '/salary-calculator/sc-work',
                          title: this.$t('salary_calculator.work.title'),
                          permission: permissions.SC_WORK,
                        },
                        {
                          href: '/salary-calculator/sc-work-log',
                          title: this.$t('salary_calculator.work_log.title'),
                          permission: permissions.SC_WORK_LOG,
                        },
                        {
                          href: '/salary-calculator/reports',
                          title: this.$t('salary_calculator.reports.title'),
                          permission: permissions.SC_REPORTS,
                        },
                      ]
                    },
                    {
                        href: '/works/cultures',
                        title: this.$t('works.name'),
                        icon: 'images/desktop/icons/works.svg',
                        child: [
                            // {
                            //     href: this.localePath('/works/manager'),
                            //     title: this.$t('works.manager.name'),
                            //     icon: 'fa fa-tractor',
                            //     permission: getPerm(permissions.WORKS, actions.r),
                            // },
                            {
                                href: '/works/cultures',
                                title: this.$t('works.cultures.name'),
                                permission: permissions.PARCELS,
                            },
                            {
                                href: '/works/parcels',
                                title: this.$t('works.parcels.name'),
                                permission: permissions.PARCELS,
                            },
                            {
                                href: '/works/persons',
                                title: this.$t('works.persons.name'),
                                permission: permissions.PERSONS,
                            },
                            {
                                href: '/works/work-machines',
                                title: this.$t('works.work_machines.name'),
                                permission: permissions.WORK_MACHINES,
                            },
                            {
                              href: '/works/inputs',
                              title: this.$t('works.inputs.name'),
                              permission: permissions.WORK_MACHINES,
                            },
                            {
                                href: '/works/applyArticles?showMapp=true',
                                title: this.$t('map.title'),
                                visible: this.user && this.user.is_superadmin === 1,
                                permission: permissions.DIGITAL_AGENDA,
                            },
                            {
                                href: '/works/applyArticles',
                                title: this.$t('digital_agenda.title'),
                                visible: this.user && this.user.is_superadmin === 1,
                                permission: permissions.DIGITAL_AGENDA,
                            },
                        ]
                    },
                    {
                      href: '/stock-management/sm-products',
                      title: this.$t('stock_management.title'),
                      icon: 'images/desktop/icons/stock-management.svg',
                      child: [
                        {
                          href: '/stock-management/sm-stocks',
                          title: this.$t('stock_management.stock.title'),
                          permission: getPerm(permissions.SM_STOCK, actions.r),
                        },
                        {
                          href: '/stock-management/sm-products',
                          title: this.$t('stock_management.products.title'),
                          permission: permissions.SM_PRODUCTS,
                        },
                        {
                          href: '/stock-management/sm-transactions',
                          title: this.$t('stock_management.transactions.title'),
                          permission: permissions.SM_TRANSACTIONS,
                        },
                        {
                          href: '/stock-management/sm-movements',
                          title: this.$t('stock_management.movements.title'),
                          permission: permissions.SM_MOVEMENTS,
                        },
                        {
                          href: '/stock-management/sm-partners',
                          title: this.$t('stock_management.partners.title'),
                          permission: permissions.SM_PARTNERS,
                        },
                        {
                          href: '/stock-management/sm-warehouses',
                          title: this.$t('stock_management.warehouses.title'),
                          permission: permissions.SM_WAREHOUSES,
                        },
                        {
                          href: '/stock-mobile',
                          title: this.$t('stock_mobile.title'),
                          visible: this.user && this.user.is_superadmin === 1,
                          permission: permissions.STOCK_MANAGEMENT,
                        },
                      ]
                    },
                    {
                      href: '',
                      title: this.$t('scouting.title'),
                      icon: 'images/icons/scouting-gray.svg',
                      child: [
                        {
                          href: '/scouting/admin',
                          title: this.$t('scouting.scouting_report'),
                        }
                      ]
                    },
                    {
                      href: '',
                      title: this.$t('arenda_management.title'),
                      icon: 'images/icons/scouting-gray.svg',
                      child: [
                        {
                          href: '/arenda-management/contracts',
                          title: this.$t('arenda_management.contracts'),
                        },
                        {
                          href: '/arenda-management/products',
                          title: this.$t('arenda_management.products'),
                        },
                        {
                          href: '/arenda-management/product-prices',
                          title: this.$t('arenda_management.product-prices'),
                        },
                        {
                          href: '/arenda-management/tariff-systems',
                          title: this.$t('arenda_management.tariff-systems'),
                        },
                        {
                          href: '/rent',
                          title: this.$t('arenda.title'),
                          visible: this.user && this.user.is_superadmin === 1,
                          permission: permissions.ARENDA,
                        },
                      ]
                    },
                    {
                      href: '',
                      title: this.$t('reporting.title'),
                      icon: 'images/icons/scouting-gray.svg',
                      child: [
                      {
                          href: '/reporting/report-groups',
                          title: this.$t('reporting.groups.menu'),
                          permission: permissions.REPORTS,
                        },
                        // {
                        //   href: '/reporting/report-management',
                        //   title: this.$t('reporting.management.menu'),
                        //   permission: permissions.REPORTS,
                        // },
                        {
                          href: '/reporting/reports',
                          title: this.$t('reporting.list.menu'),
                          permission: permissions.REPORTS,
                        },
                        // {
                        //   href: '/reporting/configuration',
                        //   title: this.$t('reporting.configuration'),
                        // },
                        // {
                        //   href: '/reporting/designer',
                        //   title: this.$t('reporting.designer'),
                        // }
                      ]
                    },
                    {
                        href: '/tools/configurator',
                        title: this.$t('tools.name'),
                        icon: 'images/desktop/icons/tools.svg',
                        child: [
                            {
                                href: '/tools/import',
                                title: this.$t('import'),
                                permission: permissions.IMPORT,
                            },
                          {
                            href: '/tools/configurator',
                            title: this.$t('import_configurator'),
                            permission: permissions.CONFIGURATOR,
                          },
                          {
                            href: '/tools/justifying-documents',
                            title: this.$t('tools.justifying_documents.title'),
                            permission: permissions.JUSTIFYING_DOCUMENTS,
                          },
                          {
                            href: '/tools/settings',
                            title: this.$t('tools.settings.title'),
                            permission: permissions.SETTINGS,
                          },
                        ]
                    },
                ]
            },
            loginClass() {
                return [
                    'col-12'
                ]
            },
          isRedirect() {
            return this.$route && this.$route.path.toString().indexOf('/redirect') > -1 && this.$route.query.fwd;
          }
        },
        methods: {
            ...mapActions({
                getDepartments: 'company/getDepartments',
            }),
            onToggleCollapse(collapsed) {
                this.menu_collapsed = collapsed;
            },
            isMobile() {
                return isMobile();
            },
          async handleGlobalValidationErrors() {
            let vm = this;

            eventBus.$on("validation-errors", (errors) => {
              errorMessageList(vm, errors);
            });

            if(!this.showed) {
              eventBus.$on("session-expired", (errors) => {
                errorMessage(vm, vm.$t('token_expired_alert_title'));
                this.showed = true;
              });
            }
          },
          async getCompanyDomainUnits(company) {
            if (company && company.uuid) {
              await this.getDepartments({
                company_uuid: company.uuid,
              });
            }
          },
          async handleRedirect() {
              if (this.isRedirect) {
                  this.$set(this, 'redirect', true);
                  await this.$router.push({
                    path: this.$route.query.fwd,
                  })
              }

              if (this.authenticated && this.$route.path === '/') {
                this.$router.push({
                  path: '/dashboard',
                }).catch((e) => {
                  console.log('force dash redir');
                });
              }
          },
        },
        async mounted() {
            let vm = this;
            const dom = await this.$store.dispatch('auth/checkDomain', {
              domain: Cookies.get('domain')
            });
            await this.$store.dispatch('auth/fetchUser');

            await this.handleRedirect();
            await this.handleGlobalValidationErrors();

            await this.getCompanyDomainUnits(dom);

            eventBus.$on("onToastMessage", (response) => {
              try{
                vm.$refs['toastMessage'].showToastMessage(response.type ?? 'error', response.message);
              } catch(e) {
                console.log('toast error', e);
              }
            });
        },
        watch: {
            $route: {
                handler: function(newVal) {
                  this.handleRedirect();
                  this.getCompanyDomainUnits();
                },
                deep: true,
            }
        }
    }
</script>

<style lang="scss" scoped>

dx-license {
  display: none !important;
}


@import "@/assets/scss/global/global";

.main-layout {
  background-color: $Gray_5;

  .sidebar-holder {
    align-items: flex-start;
    margin-top: 48px;
  }

  .sidebar {
      width: 250px;
      padding-left: 16px;
  }

  .main {
    padding-left: 48px !important;
    padding-right: 48px !important;
  }
}

.app-content {
  padding: 48px 24px 24px 24px;
  display: flex;
  flex-direction: row;

  .sidebar-auth {
    display: flex;
    width: 230px;
    padding-right: 24px;
  }

  .child-content-auth {
    flex: 1;
    padding-left: 24px;
  }

  .child-content {
    flex: 1;
  }
}
</style>
